import { findEnvVar } from './lib/helpers';

const AMPLITUDE_API_KEY = findEnvVar('AMPLITUDE_API_KEY');
const API_STATUS_PROXY_URL = findEnvVar('API_STATUS_PROXY_URL');
const ARGYLE_DEBUG_LOGS_ENABLED =
  (findEnvVar('ARGYLE_DEBUG_LOGS_ENABLED') || 'false') === 'true';
const ARGYLE_DEV_TOOLS_ENABLED =
  (findEnvVar('ARGYLE_DEV_TOOLS_ENABLED') || 'false') === 'true';
const ARGYLE_EMPLOYMENT_DATE_FORMAT = 'MM/YYYY';
const ARGYLE_FLOW_ID_SINGLE = findEnvVar('ARGYLE_FLOW_ID_SINGLE');
const ARGYLE_FLOW_ID_MULTIPLE = findEnvVar('ARGYLE_FLOW_ID_MULTIPLE');
const ARGYLE_JS_URL = findEnvVar('ARGYLE_JS_URL');
const ARGYLE_LINK_KEY = findEnvVar('ARGYLE_LINK_KEY');
const ARGYLE_LINK_VERBOSE =
  (findEnvVar('ARGYLE_LINK_VERBOSE') || 'false') === 'true';
const ARGYLE_SANDBOX = (findEnvVar('ARGYLE_SANDBOX') || 'true') !== 'false';
const MEASURE_ONE_API_HOSTNAME = findEnvVar('MEASURE_ONE_API_HOSTNAME');
const MEASURE_ONE_JS_URL = findEnvVar('MEASURE_ONE_JS_URL');
const MEASURE_ONE_WAIT_PERIOD = 90; // seconds
const MEASURE_ONE_DEBUG_LOGS_ENABLED =
  (findEnvVar('MEASURE_ONE_DEBUG_LOGS_ENABLED') || 'false') === 'true';
const CHECKR_API_BASE = findEnvVar('CHECKR_API_BASE');
const REGION_COMPLIANCE_API_BASE = findEnvVar('CHECKR_REGION_COMPLIANCE_BASE');
const APPLICANT_PORTAL_BASE = findEnvVar('CHECKR_APPLICANT_PORTAL');
const INTL_APPLICANT_PORTAL_BASE = findEnvVar('CHECKR_INTL_APPLICANT_PORTAL');
const DOMESTIC_INTL_APPLICANT_PORTAL_BASE = findEnvVar(
  'CHECKR_DOMESTIC_INTL_APPLICANT_PORTAL',
);
/* eslint-disable no-underscore-dangle */
const IS_BOT =
  window._DATADOG_SYNTHETICS_BROWSER === 'true' ||
  window.env?.req?.['x-datadog-origin'] === 'synthetics' ||
  (typeof window.env?.req?.['user-agent'] !== 'undefined' &&
    window.env?.req?.['user-agent'].match(/.*DatadogSynthetics$/) !== null);
const RECAPTCHA_SITE_KEY = IS_BOT
  ? findEnvVar('RECAPTCHA_SITE_TEST_KEY')
  : findEnvVar('RECAPTCHA_SITE_KEY');
const FILE_STACK_API_KEY = findEnvVar('FILE_STACK_API_KEY');
const IS_TEST_MODE = findEnvVar('NODE_ENV') === 'test';
const IS_PRODUCTION = process.env.NODE_ENV === 'production';
const STRIPE_PK_TEST = findEnvVar('STRIPE_PK_TEST');
const STRIPE_PK = findEnvVar('STRIPE_PK') || STRIPE_PK_TEST;
const STRIPE_URL = 'https://js.stripe.com/v3/';
const SENTRY_DSN = findEnvVar('SENTRY_DSN');
const SENTRY_ENV = findEnvVar('ENV');
const MAPBOX_API_TOKEN = findEnvVar('MAPBOX_API_TOKEN');
const SENTRY_RELEASE = findEnvVar('SENTRY_RELEASE');
const FLAGR_URL = findEnvVar('FLAGR_URL');
const ENV = findEnvVar('ENV');

const SHORT_DATE_FORMAT = 'MM/YYYY';
const FULL_DATE_FORMAT = 'MM/DD/YYYY';
const INTL_FULL_DATE_FORMAT = 'DD/MM/YYYY';
const ISO_DATE_FORMAT = 'YYYY-MM-DD';
const CANADA_ID_VERIFICATION_URL = findEnvVar('CANADA_ID_VERIFICATION_URL');
const ALLOW_CANADA_ID_REDIRECT =
  findEnvVar('ALLOW_CANADA_ID_REDIRECT') === 'true';

const US_COUNTRY_VALUE = 'US';
const US_COUNTRY_TERRITORIES = [
  'AS',
  'GU',
  'MP',
  'PR',
  'VI',
  'UM',
  US_COUNTRY_VALUE,
];
const GED_VALUE = 'GED';
const HIGHSCHOOL_VALUE = 'HighSchool';
const OTHER_DEGREE_VALUE = 'degree';
const LOWER_EDUCATION_LEVELS = [GED_VALUE, HIGHSCHOOL_VALUE];
const SUCCESS_STATUS_NAME = 'checkr.success';

const PROTECT_PROFILE_MATCH_ERROR = 'Candidate matches a Protect Profile';
const DATE_RANGE_ERROR = 'End date is out of range';

// IDV integration
const SOCURE_SDK_KEY = findEnvVar('SOCURE_SDK_KEY');
const SOCURE_JS_URL = findEnvVar('SOCURE_JS_URL');

// FLAGR flagKeys (strings)
const MEASURE_ONE_FLAGR_KEY = 'kvn4xpp2cotj2e1i8';
const HIDE_WELCOME_PAGE_FLAGR_KEY = 'kyonskey5v8bjpj7q';
const ADDRESS_AUTOCOMPLETE_FLAGR_KEY = 'k7bh76cwhherypymb';
const ARGYLE_FLOW_ID_CONTROLLER_FLAGR_KEY = 'argyle_flow_id_controller';
const INSTANT_SSN_EXCEPTIONS_FLAGR_KEY = 'kc24s5orrmzy6uvv3';
const LOOKBACK_RESTRICTION_FLAGR_KEY = 'k9qe3ngfz26xqekwh';

export {
  APPLICANT_PORTAL_BASE,
  API_STATUS_PROXY_URL,
  CANADA_ID_VERIFICATION_URL,
  ALLOW_CANADA_ID_REDIRECT,
  AMPLITUDE_API_KEY,
  ARGYLE_DEBUG_LOGS_ENABLED,
  ARGYLE_DEV_TOOLS_ENABLED,
  ARGYLE_EMPLOYMENT_DATE_FORMAT,
  ARGYLE_FLOW_ID_SINGLE,
  ARGYLE_FLOW_ID_MULTIPLE,
  ARGYLE_FLOW_ID_CONTROLLER_FLAGR_KEY,
  ARGYLE_LINK_VERBOSE,
  ARGYLE_SANDBOX,
  ARGYLE_JS_URL,
  ARGYLE_LINK_KEY,
  SOCURE_JS_URL,
  SOCURE_SDK_KEY,
  CHECKR_API_BASE,
  DOMESTIC_INTL_APPLICANT_PORTAL_BASE,
  FILE_STACK_API_KEY,
  HIDE_WELCOME_PAGE_FLAGR_KEY,
  INTL_APPLICANT_PORTAL_BASE,
  IS_BOT,
  IS_TEST_MODE,
  IS_PRODUCTION,
  LOWER_EDUCATION_LEVELS,
  MAPBOX_API_TOKEN,
  MEASURE_ONE_API_HOSTNAME,
  MEASURE_ONE_FLAGR_KEY,
  MEASURE_ONE_JS_URL,
  MEASURE_ONE_WAIT_PERIOD,
  MEASURE_ONE_DEBUG_LOGS_ENABLED,
  ADDRESS_AUTOCOMPLETE_FLAGR_KEY,
  RECAPTCHA_SITE_KEY,
  REGION_COMPLIANCE_API_BASE,
  STRIPE_PK,
  STRIPE_PK_TEST,
  STRIPE_URL,
  SENTRY_DSN,
  SENTRY_ENV,
  SHORT_DATE_FORMAT,
  FULL_DATE_FORMAT,
  INTL_FULL_DATE_FORMAT,
  ISO_DATE_FORMAT,
  ENV,
  FLAGR_URL,
  SENTRY_RELEASE,
  SUCCESS_STATUS_NAME,
  DATE_RANGE_ERROR,
  // application value constants

  US_COUNTRY_VALUE,
  US_COUNTRY_TERRITORIES,
  GED_VALUE,
  HIGHSCHOOL_VALUE,
  OTHER_DEGREE_VALUE,
  PROTECT_PROFILE_MATCH_ERROR,
  INSTANT_SSN_EXCEPTIONS_FLAGR_KEY,
  LOOKBACK_RESTRICTION_FLAGR_KEY,
};
