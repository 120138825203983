import { getFormValues, SubmissionError } from 'redux-form';
import { decamelizeKeys } from 'humps';
import moment from 'moment';
import { cloneDeep, find, mapValues, pick } from 'lodash';
import * as Sentry from '@sentry/react';
import { i18n } from '@international/mastodon-i18n';

import { trackAnalyticsEvent } from './analytics';
import {
  apiRequest,
  formatStringsInObject,
  parseErrorResponse,
  postOptions,
  processIntlLocations,
  fetchFlagr,
} from './helper';
import { conditionalCardProcessing, updateCreditCard } from './creditCard';
import { logLocationMetrics } from './healthScreening';
import { IDENTITY_VERIFICATION_REDIRECT } from './navigation';

import {
  FULL_DATE_FORMAT,
  ISO_DATE_FORMAT,
  SHORT_DATE_FORMAT,
  APPLICANT_PORTAL_BASE,
  INSTANT_SSN_EXCEPTIONS_FLAGR_KEY,
  ENV,
} from '../constants';
import { UI_SPECIFIC_INFO } from '../international/constants';
import { ANALYTICS_EVENTS } from '../lib/analytics';
import Emitter, { INTL_FORM_NEXT_PAGE } from '../lib/utils/emitter';
import { forwardToRedirect } from './configuration';

const SUBMIT_FORM_FAILURE = 'SUBMIT_FORM_FAILURE';
const SUBMIT_FORM_REQUEST = 'SUBMIT_FORM_REQUEST';
const SUBMIT_FORM_SUCCESS = 'SUBMIT_FORM_SUCCESS';

const SUBMIT_SAVESTATE_FAILURE = 'SUBMIT_SAVESTATE_FAILURE';
const SUBMIT_SAVESTATE_REQUEST = 'SUBMIT_SAVESTATE_REQUEST';
const SUBMIT_SAVESTATE_SUCCESS = 'SUBMIT_SAVESTATE_SUCCESS';

const intlForm = getFormValues('intlForm');

const submitForm = (form, configuration, employerOutOfRange = false) => {
  return {
    type: SUBMIT_FORM_REQUEST,
    form,
  };
};

const submitFormSuccess = (data, routeParams = null) => ({
  type: SUBMIT_FORM_SUCCESS,
  data,
  routeParams,
});

const submitFormSuccessIdentityVerificationRedirect = (
  data,
  identityVerificationRedirect,
) => ({
  type: IDENTITY_VERIFICATION_REDIRECT,
  data,
  identityVerificationRedirect,
});

const submitFormFailure = data => ({
  type: SUBMIT_FORM_FAILURE,
  data,
});

const submitSaveStateSuccess = () => ({
  type: SUBMIT_SAVESTATE_SUCCESS,
});

const submitSaveStateFailure = errors => ({
  type: SUBMIT_SAVESTATE_FAILURE,
  errors,
});

const defaultDateFormat = FULL_DATE_FORMAT;
const defaultExportFormat = ISO_DATE_FORMAT;
const dateISO = (
  date,
  format = defaultDateFormat,
  exportFormat = defaultExportFormat,
) => date && moment(date, format).format(exportFormat);
const parseDate = ({ month, day, year }) => `${month}/${day}/${year}`;
const postPath = resourceId => `apply/${resourceId}`;
const putAppointmentPath = resourceId =>
  `health_screenings/appointments/${resourceId}/location`;
const putAppointmentOptions = data => ({
  method: 'PUT',
  body: JSON.stringify(data),
});

const normalizeData = (values, routeParams) => {
  const data = {
    ...cloneDeep(values),
    noMiddleName: !!values.noMiddleName,
    configOptions: routeParams,
  };

  if (data.dob) {
    if (Object.keys(data.dob).length === 0) {
      delete data.dob;
    } else {
      data.dob = dateISO(parseDate(data.dob));
    }
  }

  if (data.dlExpirationDate) {
    if (Object.keys(data.dlExpirationDate).length === 0) {
      delete data.dlExpirationDate;
    } else {
      data.dlExpirationDate = dateISO(parseDate(data.dlExpirationDate));
    }
  }

  if (data.address) {
    data.address = {
      ...data.address,
      startDate: dateISO(data.address.startDate),
    };
  }

  if (data.addresses) {
    data.addresses = data.addresses.map(address => ({
      ...address,
      startDate: dateISO(address.startDate, SHORT_DATE_FORMAT),
    }));
  } else if (data.currentAddress) {
    const { countryCode, startDate } = data.currentAddress;
    data.addresses = [
      {
        ...data.currentAddress,
        countryCode: countryCode || 'US',
        startDate: dateISO(startDate, SHORT_DATE_FORMAT),
      },
    ];
    delete data.currentAddress;
  }

  if (data.criminalRecords) {
    data.criminalRecords = data.criminalRecords.map(record => ({
      ...record,
      sentenceDate: dateISO(record.sentenceDate),
    }));
  }

  if (data.employment) {
    data.employment = data.employment.map(record => {
      const dates = mapValues(pick(record, 'startDate', 'endDate'), date =>
        dateISO(date, SHORT_DATE_FORMAT),
      );

      return {
        ...record,
        ...dates,
      };
    });

    data.employment = formatStringsInObject(data.employment);
  }

  if (data.education) {
    data.education = data.education.map(s => {
      const school = {
        ...s,
        startDate: dateISO(s.startDate),
        endDate: dateISO(s.endDate),
      };

      const currentlyEnrolled = school.current === 'true';
      if (currentlyEnrolled) {
        delete school.endDate;
        delete school.yearAwarded;
      }

      return formatStringsInObject(school);
    });
  }

  if (!data?.configOptions?.hasInternationalAddresses) {
    delete data.internationalAddresses;
  }

  return data;
};

const handleFormSubmissionError = error => dispatch => {
  return parseErrorResponse(error).then(({ errors }) => {
    dispatch(submitFormFailure());

    const submissionError = new SubmissionError(errors);

    if (error.status !== 400) {
      // 400 validation errors are expected and OK
      // other errors should report to Sentry
      Sentry.captureException(submissionError, {
        extra: { serverErrors: errors },
      });
    }
    throw submissionError;
  });
};

const postApplication = (resourceId, data, company) => (dispatch, getState) => {
  return apiRequest(postPath(resourceId), postOptions(data))
    .then(json => {
      if (json.redirectLink) {
        return doRegionComplianceRedirect(json, dispatch);
      }

      if (data.ssn && data.ssn_confirmation) {
        const reportId = json.report?.id;
        const preflightClear = json.preflightSsntraceStatus === 'clear';

        if (reportId && !preflightClear) {
          return fetchFlagr(
            INSTANT_SSN_EXCEPTIONS_FLAGR_KEY,
            {
              env: ENV,
              account_uri: company,
              account_resource_id: data.account_id,
            },
            { id: reportId, type: 'report' },
          )
            .then(flagrData => {
              if (flagrData.flag.variantKey === 'on') {
                window.location = `${APPLICANT_PORTAL_BASE}instant_exceptions/${reportId}`;
              } else {
                dispatch(submitFormSuccess(json));
              }
            })
            .catch(err => {
              dispatch(submitFormSuccess(json));
            });
        }

        return dispatch(submitFormSuccess(json));
      }

      return dispatch(submitFormSuccess(json));
    })
    .catch(error => dispatch(handleFormSubmissionError(error)));
};

const handleCreditCardProcessing = (
  error,
  confirmationToken,
  data,
  dispatch,
) => {
  if (error) {
    dispatch(updateCreditCard({ error }));
    dispatch(submitFormFailure());
    throw new SubmissionError({ isValidCC: 'Invalid CC' });
  }

  if (confirmationToken) {
    // eslint-disable-next-line no-param-reassign
    data.stripeConfirmationToken = confirmationToken.id;
  }

  return data;
};

// Did any of the positions submitted have an end date out of range
// of the package's lookbackYears setting?
const hasEmployerOutOfRange = data => {
  let employerOutOfRange = false;
  const { lookbackYears } = data;

  if (data.employment) {
    // find an item, and convert to boolean
    employerOutOfRange = !!find(
      data.employment,
      record =>
        moment(record.endDate, ISO_DATE_FORMAT) <
        moment().subtract(lookbackYears, 'years'),
    );
  }
  return employerOutOfRange;
};

const parseIntlDates = (arr, shouldConvertToCurrentDate = false) => {
  return arr.filter(Boolean).map(obj => ({
    ...obj,
    start_date: dateISO(obj.start_date, i18n.getMonthYearPattern()),
    end_date: dateISO(
      obj.end_date || (shouldConvertToCurrentDate ? moment() : obj.end_date),
      i18n.getMonthYearPattern(),
    ),
  }));
};

const handleIntDates = dataToSend => {
  /* eslint-disable no-param-reassign */
  if (dataToSend?.address?.length) {
    dataToSend.address = parseIntlDates(dataToSend.address);
  }
  if (dataToSend?.employers?.length) {
    dataToSend.employers = parseIntlDates(dataToSend.employers, true);
  }
  if (dataToSend?.education?.length) {
    dataToSend.education = parseIntlDates(dataToSend.education);
  }
  /* eslint-enable no-param-reassign */
  return dataToSend;
};

const parseIntScopedRequirements = dataToSend => {
  /* eslint-disable no-param-reassign */
  /* eslint-disable-next-line camelcase */
  if (dataToSend?.scoped_requirements !== null) {
    dataToSend.scoped_requirements = parseScopedRequirements(
      dataToSend.scoped_requirements,
    );
  }
  /* eslint-enable no-param-reassign */
  /* eslint-enable camelcase */
  return dataToSend;
};

const handleIntRightToWork = (dataToSend, workLocation) => {
  /* eslint-disable no-param-reassign */
  /* eslint-disable camelcase */
  if (dataToSend?.right_to_works?.at(0)) {
    if ([null, {}, undefined].includes(dataToSend?.scoped_requirements)) {
      dataToSend.scoped_requirements = {};
    }
    dataToSend.scoped_requirements[workLocation] = {
      ...dataToSend.scoped_requirements[workLocation],
      ...dataToSend?.right_to_works.at(0),
    };
    delete dataToSend.right_to_works;
  }
  /* eslint-enable camelcase */
  /* eslint-enable no-param-reassign */
  return dataToSend;
};

const handleIntUkPastNames = dataToSend => {
  /* eslint-disable no-param-reassign */
  /* eslint-disable camelcase */
  if (
    dataToSend?.scoped_requirements?.GB &&
    dataToSend.aliases &&
    !dataToSend.credit_report
  ) {
    dataToSend.scoped_requirements.GB.aliases = dataToSend.aliases;
    delete dataToSend.aliases;
  }
  /* eslint-enable camelcase */
  /* eslint-enable no-param-reassign */
  return dataToSend;
};

const handleIntCreditReport = (dataToSend, workLocation) => {
  /* eslint-disable no-param-reassign */
  /* eslint-disable camelcase */
  if (dataToSend?.credit_report?.at(0)) {
    if ([null, {}, undefined].includes(dataToSend.scoped_requirements)) {
      dataToSend.scoped_requirements = {};
    }
    dataToSend.scoped_requirements[workLocation] = {
      ...dataToSend.scoped_requirements[workLocation],
      ...dataToSend.credit_report.at(0),
      aliases: dataToSend?.aliases,
      no_aliases: dataToSend?.no_aliases,
    };
    delete dataToSend.credit_report;
  }
  /* eslint-enable camelcase */
  /* eslint-enable no-param-reassign */
  return dataToSend;
};

const handleIntlFormSubmit = (data, metadata) => (dispatch, getState) => {
  const { routeParams, configuration } = getState().configuration;
  const resourceId = configuration.package.version_id;

  let dataToSend = { ...data };
  const workLocation = dataToSend.data_residency_location;
  delete dataToSend[UI_SPECIFIC_INFO];

  dataToSend = handleIntDates(dataToSend);

  dataToSend = parseIntScopedRequirements(dataToSend);

  dataToSend = handleIntRightToWork(dataToSend, workLocation);

  dataToSend = handleIntUkPastNames(dataToSend);

  dataToSend = handleIntCreditReport(dataToSend);

  const intlLocations = processIntlLocations(data.address);

  if (workLocation === 'CA') {
    for (const address of dataToSend.address || []) {
      if (address.country_code && !address.start_date?.replace(/\W/gi, '')) {
        Sentry.captureException(
          new Error('From date is required for Canadian address'),
          {
            extra: {
              rawAddresses: data.address,
              addresses: dataToSend.address,
            },
          },
        );
      }
    }
  }

  const finalPayload = {
    metadata,
    data: {
      ...dataToSend,
      dob: dateISO(
        dataToSend.dob,
        i18n.getLocalizedDateFormat(i18n.DateFormats.DATE_SLASH),
      ),
      international_consent_form: {
        signed: true,
      },
    },
    recaptcha_code: dataToSend.recaptchaCode,
    locations: intlLocations,
    token: routeParams.token,
  };

  dispatch({ type: SUBMIT_FORM_REQUEST });
  return apiRequest(postPath(resourceId), postOptions(finalPayload))
    .then(json => {
      if (json.redirectLink) {
        if (
          metadata?.['candidate_tasks']?.['identity-verification-task'] !==
            undefined ||
          json.redirectLink.startsWith('https://apply.')
        ) {
          forwardToRedirect(json.redirectLink);
        } else {
          configuration?.account['logo_uri'] &&
            window.localStorage.setItem(
              'headerLogoUri',
              configuration.account.logo_uri,
            );
          Emitter.emit(INTL_FORM_NEXT_PAGE);
          doRegionComplianceRedirect(json, dispatch);
        }
      } else {
        dispatch(submitFormSuccess(json));
      }
    })
    .catch(error => {
      // dispatching handleFormSubmissionError loses the throw submissionError
      // copied contents for functionality until that can be resolved
      return parseErrorResponse(error).then(({ errors }) => {
        dispatch(submitFormFailure());
        const submissionError = new SubmissionError(errors);
        if (error.status !== 400) {
          // 400 validation errors are expected and OK
          // other errors should report to Sentry
          Sentry.captureException(submissionError, {
            extra: { serverErrors: errors },
          });
        }
        throw submissionError;
      });
    });
};

const doRegionComplianceRedirect = (json, dispatch) => {
  let locale = i18n.getLocale();
  locale = locale.length > 2 ? locale.replace('_', '-') : locale;
  dispatch(
    submitFormSuccessIdentityVerificationRedirect(
      json,
      `${json.redirectLink}&language=${locale}`,
    ),
  );
};

const parseScopedRequirements = scopedRequirements => {
  const dataToSend = { ...scopedRequirements };
  // eslint-disable-next-line camelcase
  if (dataToSend.GB?.drivers_license_issue_date != null)
    dataToSend.GB.drivers_license_issue_date = dateISO(
      dataToSend.GB.drivers_license_issue_date,
      i18n.getLocalizedDateFormat(),
      ISO_DATE_FORMAT,
    );

  if (dataToSend.CA) {
    if (dataToSend.CA.violations) dataToSend.CA.no_violations = false;
    if (dataToSend.CA.aliases) dataToSend.CA.no_aliases = false;
  }
  return dataToSend;
};

const handleIntlFormSaveState = () => (dispatch, getState) => {
  const data = intlForm(getState());
  const dataToSend = { ...data };
  const { routeParams } = getState().configuration;
  if (dataToSend?.address?.length) {
    dataToSend.address = parseIntlDates(dataToSend.address);
  }
  if (dataToSend?.employers?.length) {
    dataToSend.employers = parseIntlDates(dataToSend.employers);
  }
  if (dataToSend?.education?.length) {
    dataToSend.education = parseIntlDates(dataToSend.education);
  }

  const finalPayload = {
    data: {
      ...dataToSend,
      dob: dateISO(
        dataToSend.dob,
        i18n.getLocalizedDateFormat(i18n.DateFormats.DATE_SLASH),
      ),
    },
  };
  const { company, token, isTest } = routeParams;
  const savePath = `invite/${company}/${token}/save?test=${isTest}`;

  dispatch({ type: SUBMIT_SAVESTATE_REQUEST });
  return apiRequest(savePath, postOptions(finalPayload))
    .then(() => {
      dispatch(submitSaveStateSuccess());
      window.scrollTo(0, 0);
      dispatch(trackAnalyticsEvent(ANALYTICS_EVENTS.APPLICATION_SAVED));
    })
    .catch(error => {
      return parseErrorResponse(error).then(({ errors }) => {
        dispatch(submitSaveStateFailure(errors));
        window.scrollTo(0, 0);
        if (error.status !== 400) {
          const submissionError = new SubmissionError(errors);
          Sentry.captureException(submissionError, {
            extra: { serverErrors: errors },
          });
        }
      });
    });
};

const logHealthScreeningLocationMetrics = (values, configuration) => {
  logLocationMetrics(
    values.accountId,
    configuration.account.name,
    values.node,
    configuration.package.id,
    values.location,
  );
};

const handleFormSubmit = values => (dispatch, getState) => {
  const { creditCard, configuration } = getState();
  const configObject = configuration.configuration;
  const { routeParams } = configuration;
  const data = normalizeData(values, routeParams);

  const employerOutOfRange = hasEmployerOutOfRange(data);
  dispatch(submitForm(data, configObject, employerOutOfRange));

  logHealthScreeningLocationMetrics(values, configObject);

  return conditionalCardProcessing(creditCard)
    .then(res =>
      handleCreditCardProcessing(
        res.error,
        res?.confirmationToken || null,
        data,
        dispatch,
      ),
    )
    .then(incomingData => {
      const scopedRequirements = incomingData.scoped_requirements;
      const decamelizeData = decamelizeKeys(incomingData);
      if (scopedRequirements) {
        decamelizeData.scoped_requirements = parseScopedRequirements(
          scopedRequirements,
        );
      }
      return dispatch(
        postApplication(
          configObject.package.versionId,
          decamelizeData,
          routeParams.company,
        ),
      );
    });
};

const handleAppointmentFormSubmit = values => (dispatch, getState) => {
  const {
    configuration: { configuration },
  } = getState();
  const appointmentId = configuration.id;
  const putData = decamelizeKeys(values);

  logHealthScreeningLocationMetrics(values, configuration);

  dispatch({ type: SUBMIT_FORM_REQUEST });
  return apiRequest(
    putAppointmentPath(appointmentId),
    putAppointmentOptions(putData),
  )
    .then(json => dispatch(submitFormSuccess(json)))
    .catch(error => dispatch(handleFormSubmissionError(error)));
};

export {
  normalizeData,
  SUBMIT_FORM_FAILURE,
  SUBMIT_FORM_REQUEST,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_SAVESTATE_FAILURE,
  SUBMIT_SAVESTATE_REQUEST,
  SUBMIT_SAVESTATE_SUCCESS,
  handleAppointmentFormSubmit,
  handleFormSubmit,
  handleIntlFormSubmit,
  handleIntlFormSaveState,
  submitFormSuccess,
  submitFormFailure,
  submitSaveStateFailure,
};
